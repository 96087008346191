import addressObj from 'thai_address_th_en'
addressObj.locale('th');

export const getProvinces = () => {
  const mProvince = addressObj.provinces(res => res);
  return mProvince
}

export const getDistricts = (province) => {
  const mAmphur = addressObj.district({ province: province }, (res,err) => {
    if(err) {
      return []
    } else {
      return res
    }
  });
  return mAmphur;
}

export const getSubDistricts = (province, amphoe) => {
  const mTambol = addressObj.subDistrict({
    province: province,
    district: amphoe
  }, (res,err) => {
      if(err) {
        return []
      } else {
        return res
      }
  });
  return mTambol;
}

export const multiLingualAddress = mAddress => {
  addressObj.locale('en');
  let provinceEn = addressObj.convertTHToEN({
    type: "p",
    data: {
      province : mAddress && mAddress.province && mAddress.province.th
    }
  }, (res) => res);
  let districtEn = addressObj.convertTHToEN({
    type: "d",
    data: {
      province : mAddress && mAddress.province && mAddress.province.th,
      district : mAddress && mAddress.district && mAddress.district.th
    }
  }, (res) => res);
  let subDistrictEn = addressObj.convertTHToEN({
    type: "sd",
    data: {
      province : mAddress && mAddress.province && mAddress.province.th,
      district : mAddress && mAddress.district && mAddress.district.th,
      subDistrict : mAddress && mAddress.subdistrict && mAddress.subdistrict.th
    }
  },(res) => res);
  return {
    _id: mAddress && mAddress._id,
    address: mAddress && mAddress.address,
    province: {
      th: mAddress && mAddress.province && mAddress.province.th || '',
      en: provinceEn || ''
    },
    district: {
      th: mAddress && mAddress.district && mAddress.district.th || '',
      en: districtEn || ''
    },
    subdistrict: {
      th: mAddress && mAddress.subdistrict && mAddress.subdistrict.th || '',
      en: subDistrictEn && subDistrictEn.subDistrict || ''
    },
    zipcode: mAddress && mAddress.zipcode,
    country: mAddress && mAddress.country
  }
}